<template>
  <transition appear enter-active-class="animated fadeIn">
    <div>
      <div class="level-item">
        <h1 class="title is-1 page-title">MaPS Directory</h1>
      </div>
      <div class="box b-shdw-3">
				<InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>

				<div class="content">

					<p class="mb-4">
						It is essential that you have the most extensive cover when you are on holiday, particularly if you have pre-existing medical conditions. At Covered2go, we can consider most medical conditions, and our aim is to provide you with quality travel insurance policies that suit the requirements of our customers. 
					</p>

					<p class="mb-4">
						Our aim is to provide competitively priced, quality, travel insurance policies, ensuring we always consider our customers needs first. We would therefore like to highlight, that should you consider our prices for pre-existing medical conditions cover, too high, or we are unable to cover your condition, then you have free and unrestricted access to a pool of specialist travel insurance providers on the directory provided by the <a href="https://traveldirectory.moneyadviceservice.org.uk/en/listings" target="_blank">Money and Pensions Service (MaPS)</a>.
					</p>

					<h2>Can Covered2o provide cover for my conditions?</h2>
					<p class="mb-4">
						We are confident that our medical screening quotation system will provide cover in most circumstances, with decisions being instantaneous. 
					</p>
					<p class="mb-4">
						Simply <a href="https://www.covered2go.co.uk/get-quote">get a quote</a> or <a href="https://www.covered2go.co.uk/contact-us">contact us</a> to discuss further.
					</p>

					<h2>Who are the Money and Pensions Service (MaPS)?</h2>
					<p class="mb-4">
						MaPS was established at the beginning of 2019 and engages with HM Treasury on policy matters relating to financial capability and debt advice.
					</p>
					<p class="mb-4">
						The MaPS travel insurance directory has been created for travellers who have potentially been refused cover or received a high additional premium in connection with their medical conditions, which provides them with access to consider alternative providers. 
					</p>

					<h2>How can I access the MaPS Directory?</h2>
					<ul class="mb-5">
						<li>By Phone – call 0800 138 7777 (Monday – Friday 8am to 6pm)</li>
						<li>Online – you can visit the online travel insurance MaPS directory for a full list of participating travel insurance brands by <a href="https://traveldirectory.moneyadviceservice.org.uk/en/listings" target="_blank">clicking here</a>.</li>
						<li>By Email – simply contact <a href="mailto:traveldirectory@maps.org.uk">traveldirectory@maps.org.uk</a>.</li>
					</ul>
					
				</div>
				
				<InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
				<info-boxes/>
				<trustpilot-banner/>

			</div>
    </div>
  </transition>
</template>

<script>
import InfoBoxes from "../components/common/InfoBoxes";
import TrustpilotBanner from "@/components/common/trustpilot/TrustpilotBanner";
import InfoButtonOptions from "@/components/common/InfoButtonOptions";
		
export default {
  name: 'MapsDirectory',
  mixins: [],
  components: {
			InfoBoxes,
			TrustpilotBanner,
			InfoButtonOptions
	},
};
</script>

<style lang="scss" scoped>
@import 'src/styles/views/info-page';
</style>
